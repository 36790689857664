export const woonenergieBadgeColors = {
  badgeSuccessBackground: '{{ secondaryColors.green100 }}',
  badgeSuccessText: '{{ textColors.textOnBackgroundVarFive }}',

  badgeWarningBackground: '{{ secondaryColors.yellow100 }}',
  badgeWarningText: '{{ textColors.textOnBackgroundVarSix }}',

  badgeErrorBackground: '{{ secondaryColors.pink100 }}',
  badgeErrorText: '{{ secondaryColors.pink900 }}',
};
