import { Fonts } from '../../../types';

export {
  BASE_FONT_SIZE,
  BASE_FONT_RELATIVE,
  fontSizes,
  letterSpacings,
  lineHeights,
} from '../../eneco/default/tokens/typography';

/**
 * Primary tokens
 */
export const fonts: Fonts = [
  ['SunOT', 700, 'sunOTBold.otf', 'opentype'],
  ['Palanquin', 400, 'palanquinRegular.ttf', 'truetype'],
  ['Palanquin', 700, 'palanquinBold.ttf', 'truetype'],
];

export const fontWeights = {
  bodyRegular: '400',
  bodyBold: '700',
  headingRegular: '700',
};

export const bodyTypography = {
  bodyXL: {
    fontSize: '{{ fontSizes.BodyXL }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[7] }}',
  },
  bodyL: {
    fontSize: '{{ fontSizes.BodyL }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[6] }}',
  },
  bodyM: {
    fontSize: '{{ fontSizes.BodyM }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[6] }}',
  },
  bodyS: {
    fontSize: '{{ fontSizes.BodyS }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[5] }}',
  },
  bodyXS: {
    fontSize: '{{ fontSizes.BodyXS }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[4] }}',
  },
  quoteM: {
    fontSize: '{{ fontSizes[32] }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[9] }}',
  },
  quoteS: {
    fontSize: '{{ fontSizes[24] }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[7] }}',
  },
};

export const headingTypography = {
  heading3xl: {
    fontSize: '{{ fontSizes[3XL] }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.narrowM }}',
    lineHeight: '{{ lineHeights[20] }}',
  },
  heading2xl: {
    fontSize: '{{ fontSizes[2XL] }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.narrowM }}',
    lineHeight: '{{ lineHeights[16] }}',
  },
  headingXl: {
    fontSize: '{{ fontSizes.XL }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.narrowM }}',
    lineHeight: '{{ lineHeights[12] }}',
  },
  headingL: {
    fontSize: '{{ fontSizes.L }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.narrowS }}',
    lineHeight: '{{ lineHeights[10] }}',
  },
  headingM: {
    fontSize: '{{ fontSizes.M }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[9] }}',
  },
  headingS: {
    fontSize: '{{ fontSizes.S }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[7] }}',
  },
  headingXs: {
    fontSize: '{{ fontSizes.XS }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[6] }}',
  },
  heading2xs: {
    fontSize: '{{ fontSizes[2XS] }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.normal }}',
    lineHeight: '{{ lineHeights[6] }}',
  },
  heading3xs: {
    fontSize: '{{ fontSizes[3XS] }}',
    fontWeight: '{{ fontWeights.headingRegular }}',
    letterSpacing: '{{ letterSpacings.wide }}',
    lineHeight: '{{ lineHeights[5] }}',
  },
};
