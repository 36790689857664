export const brandColors = {
  brandBlue: '#007CDD',
  brandLightBlue: '#16B5FF',
};

export const brandGradients = {
  brandGradientStart: '{{ brandColors.brandBlue }}',
  brandGradientEnd: '{{ brandColors.brandLightBlue }}',
  brandGradient: 'linear-gradient(90deg, {{ brandColors.brandBlue }} 0%, {{ brandColors.brandLightBlue }} 100%)',
};

export const brandSupport = {
  lime: '#C7D416',
  lightLime: '#E7EE86',
};

export const neutralColors = {
  neutralWhite: '#FFFFFF',
  neutral25: '#F5F7F8',
  neutral100: '#ECF0F2',
  neutral200: '#D9DEE1',
  neutral300: '#B5BDBF',
  neutral400: '#8C989D',
  neutral500: '#68727A',
  neutral900: '#34363D',
};

// For a hex to percentage opacity conversion, see https://davidwalsh.name/hex-opacity
export const opacityColors = {
  blackOpacity40: '#00000040',
  greyOpacity70: '{{ neutralColors.neutral900 }}70',
};

export const secondaryColors = {
  // Purple
  purple50: '#BFABFA',
  purple100: '#6952A9',

  // blue
  blue25: '#E4EBEE',
  blue100: '#3E798D',
  blue200: '#09354B',

  // green
  green25: '#D8E7CC',
  green50: '#B1D098',
  green100: '#63A132',

  // Red
  red25: '#F1DCE0',
  red100: '#BB0E2D',
  red100Opacity30: 'rgba(187, 14, 45, 0.3)',

  // Orange
  orange25: '#FAEEDB',
  orange100: '#EB8D00',

  // Yellow
  yellow25: '#F5D87F',
  yellow100: '#FACB35',
  yellow200: '#EBB300',

  // Pink
  pink100: '#FBDBE3',
  pink900: '#552748',
};

// General decision tokens

export const backgroundColors = {
  backgroundPrimary: '{{ neutralColors.neutralWhite }}',
  backgroundSecondary: '{{ neutralColors.neutral25 }}',
  backgroundTertiary: '{{ neutralColors.neutral200 }}',
  backgroundBrand: '{{ brandGradients.brandGradient }}',
  backgroundScrim: '{{ opacityColors.greyOpacity70 }}',
  backgroundDark: '{{ neutralColors.neutral900 }}',
  backgroundPressed: '{{ neutralColors.neutral100 }}',
};

export const backgroundColoredColors = {
  backgroundVarOne: '{{ neutralColors.neutralWhite }}',
  backgroundVarTwo: '{{ neutralColors.neutralWhite }}',
  backgroundVarThree: '{{ neutralColors.neutralWhite }}',
  backgroundVarFour: '{{ neutralColors.neutralWhite }}',
  backgroundVarFive: '{{ neutralColors.neutralWhite }}',
  backgroundVarSix: '{{ neutralColors.neutralWhite }}',
};

export const backgroundSitecoreColors = {
  backgroundCMSVarOne: '{{ brandColors.brandLightBlue }}',
  backgroundCMSVarTwo: '{{ brandSupport.lime }}',
  backgroundCMSVarThree: '{{ brandSupport.lightLime }}',
  backgroundCMSVarFour: '{{ neutralColors.neutral25 }}',
  backgroundCMSVarFive: '{{ neutralColors.neutral100 }}',
  backgroundCMSVarSix: '{{ neutralColors.neutral200 }}',
  backgroundCMSVarSeven: '{{ neutralColors.neutral200 }}',
  backgroundCMSVarEight: '{{ neutralColors.neutral200 }}',
};

export const textColors = {
  textPrimary: '{{ neutralColors.neutral900 }}',
  textInverted: '{{ neutralColors.neutralWhite }}',
  textBrand: '{{ brandColors.brandBlue }}',
  textOnBackgroundVarOne: '{{ neutralColors.neutral900 }}',
  textOnBackgroundVarTwo: '{{ neutralColors.neutral900 }}',
  textOnBackgroundVarThree: '{{ neutralColors.neutral900 }}',
  textOnBackgroundVarFour: '{{ neutralColors.neutral900 }}',
  textOnBackgroundVarFive: '{{ neutralColors.neutral900 }}',
  textOnBackgroundVarSix: '{{ neutralColors.neutral900 }}',
  textLowEmphasis: '{{ neutralColors.neutral500 }}',
  textHighlightVarOne: '{{ neutralColors.neutral900 }}',
  textHighlightVarTwo: '{{ neutralColors.neutral900 }}',
  textHighlightVarThree: '{{ neutralColors.neutral900 }}',
  textHighlightVarFour: '{{ neutralColors.neutral900 }}',
  textHighlightVarFive: '{{ neutralColors.neutral900 }}',
  textHighlightVarSix: '{{ neutralColors.neutral900 }}',
};

export const iconColors = {
  iconPrimary: '{{ neutralColors.neutral900 }}',
  iconSecondary: '{{ neutralColors.neutral500 }}',
  iconTertiary: '{{ neutralColors.neutral900 }}',
  iconInverted: '{{ neutralColors.neutralWhite }}',
  iconBrand: '{{ brandColors.brandBlue }}',
  iconGas: '{{ secondaryColors.purple100 }}',
  iconHeat: '{{ neutralColors.neutral900 }}',
  iconCooling: '{{ neutralColors.neutral900 }}',
  iconWater: '{{ neutralColors.neutral900 }}',
  iconTotal: '{{ brandSupport.lime }}',
  iconElectricity: '{{ secondaryColors.green100 }}',
  iconSolar: '{{ secondaryColors.yellow100 }}',
  iconOnBackgroundVarOne: '{{ neutralColors.neutral900 }}',
  iconOnBackgroundVarTwo: '{{ neutralColors.neutral900 }}',
  iconOnBackgroundVarThree: '{{ neutralColors.neutral900 }}',
  iconOnBackgroundVarFour: '{{ neutralColors.neutral900 }}',
  iconOnBackgroundVarFive: '{{ neutralColors.neutral900 }}',
  iconOnBackgroundVarSix: '{{ neutralColors.neutral900 }}',
  currentColor: 'currentColor',
};

export const borderColors = {
  borderDividerLowEmphasis: '{{ neutralColors.neutral200 }}',
  borderDividerMediumEmphasis: '{{ neutralColors.neutral300 }}',
  borderDividerHighEmphasis: '{{ neutralColors.neutral500 }}',
  borderFocus: '{{ neutralColors.neutral900 }}',
  borderSelected: '{{ secondaryColors.green100 }}',
  outlineHover: '{{ neutralColors.neutral100 }}',
};

export const linkColors = {
  linkBrand: '{{ textColors.textBrand }}',
  linkPrimary: '{{ textColors.textPrimary }}',
  linkSecondary: '{{ textColors.textLowEmphasis }}',
  linkDisabled: '{{ neutralColors.neutral200 }}',
  linkInverted: '{{ textColors.textInverted }}',
};

export const controlColors = {
  controlsActive: '{{ secondaryColors.green100 }}',
  controlsInactive: '{{ neutralColors.neutral200 }}',
  controlsKnob: '{{ neutralColors.neutralWhite }}',
};

export const feedbackColors = {
  feedbackError: '{{ secondaryColors.red100 }}',
  feedbackSuccess: '{{ secondaryColors.green100 }}',
  feedbackWarning: '{{ secondaryColors.orange100 }}',
  feedbackInfo: '{{ secondaryColors.blue100 }}',
  feedbackBackgroundError: '{{ secondaryColors.red25 }}',
  feedbackBackgroundSuccess: '{{ secondaryColors.green25 }}',
  feedbackBackgroundWarning: '{{ secondaryColors.orange25 }}',
  feedbackBackgroundInfo: '{{ secondaryColors.blue25 }}',
};

export const formColors = {
  formBorderDefault: '{{ neutralColors.neutral300 }}',
  formBorderError: '{{ secondaryColors.red100 }}',
  formBorderHover: '{{ neutralColors.neutral400 }}',
  formErrorMessageBackground: '{{ secondaryColors.red100 }}',
  formOutlineError: '{{ secondaryColors.red100Opacity30 }}',
};

export const graphsColors = {
  graphsTotal: '{{ brandSupport.lime }}',
  graphsGasPrimary: '{{ secondaryColors.purple100 }}',
  graphsGasSecondary: '{{ secondaryColors.purple50 }}',
  graphsElectricityPrimary: '{{ secondaryColors.green100 }}',
  graphsElectricitySecondary: '{{ secondaryColors.green50 }}',
  graphsSolarPrimary: '{{ secondaryColors.yellow100 }}',
  graphsSolarSecondary: '{{ secondaryColors.yellow25 }}',
  graphsWarmthPrimary: '{{ neutralColors.neutral900 }}',
  graphsWaterPrimary: '{{ neutralColors.neutral900 }}',
  graphsFixedCosts: '{{ neutralColors.neutral400 }}',
  graphsEstimatedPrimary: '{{ neutralColors.neutral100 }}',
  graphsEstimatedSecondary: '{{ neutralColors.neutral500 }}',
  graphComparePrimary: '{{ secondaryColors.yellow100 }}',
  graphCompareSecondary: '{{ brandColors.brandBlue }}',
  graphCompareStickerPositive: '{{ secondaryColors.green100 }}',
  graphCompareStickerNeutral: '{{ secondaryColors.blue100 }}',
  graphCompareStickerNegative: '{{ secondaryColors.orange100 }}',
  graphCompareInnerTextColor: '{{ neutralColors.neutral900 }}',
};

export const globalThemeColors = {
  ...neutralColors,
  ...opacityColors,
  ...brandColors,
  ...brandGradients,
  ...backgroundColors,
  ...backgroundColoredColors,
  ...backgroundSitecoreColors,
  ...textColors,
  ...iconColors,
  ...borderColors,
  ...linkColors,
  ...controlColors,
  ...feedbackColors,
  ...formColors,
  ...graphsColors,
};
