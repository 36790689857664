export const woonenergieButtonColors = {
  // Primary onLight Text
  buttonPrimaryOnLightDefaultText: '{{ textColors.textInverted }}',

  // Primary onLight Background
  buttonPrimaryOnLightDefaultBackground: '{{ brandGradients.brandGradient }}',
  buttonPrimaryOnLightHoverBackground: '{{ secondaryColors.blue200 }}',
  buttonPrimaryOnLightFocusBackground: '{{ brandGradients.brandGradient }}',
  buttonPrimaryOnLightPressedBackground: '{{ secondaryColors.blue200 }}',

  // Primary onDark Text
  buttonPrimaryOnColorDefaultText: '{{ textColors.textPrimary }}',
  buttonPrimaryOnColorHoverText: '{{ textColors.textBrand }}',
  buttonPrimaryOnColorFocusText: '{{ textColors.textBrand }}',
  buttonPrimaryOnColorPressedText: '{{ textColors.textBrand }}',

  // Primary onDark Background
  buttonPrimaryOnColorDefaultBackground: '{{ neutralColors.neutralWhite }}',

  // Primary onDark Icon
  buttonPrimaryOnColorDefaultIcon: '{{ iconColors.iconPrimary }}',
  buttonPrimaryOnColorHoverIcon: '{{ iconColors.iconBrand }}',
  buttonPrimaryOnColorFocusIcon: '{{ iconColors.iconBrand }}',
  buttonPrimaryOnColorPressedIcon: '{{ iconColors.iconBrand }}',

  // Secondary
  buttonSecondaryDefaultText: '{{ textColors.textPrimary }}',
  buttonSecondaryDefaultBackground: '{{ neutralColors.neutral200 }}',
  buttonSecondaryDefaultBorderColor: '{{ neutralColors.neutral200 }}',
  buttonSecondaryHoverText: '{{ textColors.textPrimary }}',
  buttonSecondaryHoverBackground: '{{ neutralColors.neutral300 }}',
  buttonSecondaryHoverBorderColor: '{{ neutralColors.neutral300 }}',
  buttonSecondaryFocusBackground: '{{ neutralColors.neutral300 }}',
  buttonSecondaryFocusBorderColor: '{{ neutralColors.neutral300 }}',
  buttonSecondaryPressedBackground: '{{ neutralColors.neutral300 }}',

  // Temporary rebrand definition, fallback to primary. Introduced to align tokens
  //  with Eneco, who has a temporary set of rebrand tokens in place.
  buttonPrimaryRebrandDefaultText: '{{ textColors.textInverted }}',
  buttonPrimaryRebrandDefaultBackground: '{{ brandGradients.brandGradient }}',
  buttonPrimaryRebrandHoverBackground: '{{ secondaryColors.blue200 }}',
  buttonPrimaryRebrandPressedBackground: '{{ secondaryColors.blue200 }}',

  // Loading
  buttonLoadingDefaultText: '{{ neutralColors.neutralWhite }}',
  buttonLoadingDefaultBackground: '{{ brandGradients.brandGradient }}',
};

export const woonenergieButtonBorderWidths = {
  buttonSecondaryDefaultBorderWidth: '{{ borderWidths.none }}',
};

export const woonenergieButtonBorderRadii = {
  buttonBorderRadiusDefault: '{{ radii.round }}',
  // Temporary rebrand definition, fallback to primary. Introduced to align tokens
  //  with Eneco, who has a temporary set of rebrand tokens in place.
  buttonBorderRadiusRebrand: '{{ radii.round }}',
};

export const woonenergieButtonChevronDisplay = {
  buttonChevronDisplay: 'none',
};
