import tokens from './tokens';

const theme = {
  colors: {
    ...tokens.globalThemeColors,
    ...tokens.button.woonenergieButtonColors,
    ...tokens.expandable.woonenergieExpandableTokens,
    ...tokens.desktopHeader.woonenergieHeaderColors,
    ...tokens.messageBar.woonenergieMessagebarColors,
    ...tokens.navLink.woonenergieNavlinkOncolorColors,
    ...tokens.progressIndicator.woonenergieProgressIndicatorTokensColors,
    ...tokens.ribbon.woonenergieRibbonColors,
    ...tokens.badge.woonenergieBadgeColors,
  },
  shadows: {
    ...tokens.boxShadows,
    ...tokens.borderShadows,
  },
  outlines: tokens.outlines,
  fonts: {
    body: 'Palanquin, sans-serif',
    heading: 'SunOT',
  },
  fontSizes: tokens.fontSizes,
  fontWeights: tokens.fontWeights,
  letterSpacings: tokens.letterSpacings,
  lineHeights: tokens.lineHeights,
  space: tokens.space,
  sizes: tokens.sizes,
  borderWidths: {
    ...tokens.borderWidths,
    ...tokens.button.woonenergieButtonBorderWidths,
    ...tokens.desktopHeader.woonenergieHeaderDividerHeight,
  },
  radii: {
    ...tokens.radii,
    ...tokens.button.woonenergieButtonBorderRadii,
  },
  transitions: tokens.transitions,
  typography: {
    ...tokens.bodyTypography,
    ...tokens.headingTypography,
  },
  opacities: tokens.opacities,
  display: {
    ...tokens.button.woonenergieButtonChevronDisplay,
  },
  components: {
    // @todo all component tokens should be collected here eventually..
    image: tokens.image.woonenergieImageTokens,
  },
};

export { theme };
