import { theme } from './theme';
import tokens from './tokens';
import { getFontFaces } from '../../util';

const globalStyles = {
  '@font-face': getFontFaces(tokens.fonts),
  '@media (prefers-reduced-motion: no-preference)': {
    ':root': {
      'scroll-behavior': 'smooth',
    },
  },
  ':root': {
    fontSize: tokens.BASE_FONT_RELATIVE,
  },
  body: {
    fontFamily: theme.fonts.body,
    fontWeight: theme.fontWeights.bodyRegular,
    lineHeight: theme.lineHeights['6'],
    fontSize: theme.fontSizes.BodyM,
    color: theme.colors.textPrimary,
    margin: 0,

    '&#nl-woonenergie-selfservice': {
      backgroundColor: theme.colors.backgroundSecondary,
    },
  },

  'a, button': {
    '&:focus': {
      outline: theme.outlines.outlineFocus,
    },
    '@supports selector(:focus-visible)': {
      '&:focus': {
        outline: 'none',
      },
      '&:focus-visible': {
        outline: theme.outlines.outlineFocus,
      },
    },
  },

  'button, input, select, textarea': {
    fontFamily: theme.fonts.body,
    fontWeight: theme.fontWeights.bodyRegular,
  },

  '.seamly-placeholder': {
    zIndex: 2,
    position: 'relative',
    pointerEvents: 'all',
  },
};

const themes = {
  default: theme,
};

export const BASE_FONT_RELATIVE = tokens.BASE_FONT_RELATIVE;
export const iconSizes = tokens.iconSizes;
export { globalStyles, theme, themes };
