export const woonenergieHeaderColors = {
  navBackgroundPrimary: '{{ neutralColors.neutralWhite }}',
  navBackgroundSecondary: '{{ brandGradients.brandGradient }}',
  navBackgroundTertiary: '{{ brandColors.brandLightBlue }}',
  navTextLabel: '{{ neutralColors.neutralWhite }}',
};

export const woonenergieHeaderDividerHeight = {
  navDividerHeight: '{{ borderWidths.none }}',
};
